section.featured-news {
  padding: 110px 20px;
  background-image: url('/themes/medusa/assets/dist/images/featured-news.png');
  background-size: cover;
  position: relative;
  z-index: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:after {
    background: rgba(0, 0, 0, 0.7);
  }

  h2 {
    margin: 0 0 20px;
    color: $heading-text-light;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    font-style: normal;
    font-size: 30px;
    text-align: center;

    &:before {
      content: '';
      background: url('/themes/medusa/assets/dist/images/ICN-News.png') no-repeat;
      width: 100px;
      height: 76px;
      position: relative;
      display: block;
      background-size: contain;
      margin: 0 auto 30px;
    }

    &:after {
      position: relative;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      margin: 20px auto;
      width: 75px;
      height: 5px;
      background: url('/themes/medusa/assets/dist/images/DIVIDER.png') no-repeat;
      content: '';
      background-size: contain;
    }
  }

  .news-items {
    padding: 30px 0;
    width: 100%;
    display: block;

    @include medium-up {
      display: flex;
      padding: 50px 0;
      max-width: 1600px;
      margin: 0 auto;
    }

    .news-item {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      vertical-align: top;
      background: white;
      border-radius: 10px;
      margin: 15px 0;
      padding: 50px 30px;

      @include medium-up {
        width: 33%;
        margin: 0 15px;
        padding: 60px 40px;
      }

      h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 700;

        a {
          color: $primary-color;
          text-decoration: none;
          line-height: 24px;
          border: none;

          &:hover,
          &:focus {
            color: $tertiary-color;
          }
        }
      }

      .news-item-image {
        width: 100%;
        display: none;
      }

      .news-item-blurb,
      .news-item-date {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }

      .news-item-date {
        color: $news-date-color;
        letter-spacing: 1px;
        margin-bottom: 25px;
      }

      .news-item-read-more {

        a {
          color: $primary-color;
          text-decoration: none;
          font-weight: 700;
          font-size: 16px;

          &:hover,
          &:focus {
            color: $tertiary-color;
          }
        }
      }
    }
  }

  .view-all-news {
    padding: 35px 0 0;
    text-align: center;

    a {
      color: $news-view-all-color;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      border: none;
      letter-spacing: 1px;

      &:after {
        content: '';
        background: url('/themes/medusa/assets/dist/images/icn-news-arrow.png');
        position: relative;
        bottom: 0;
        width: 35px;
        height: 35px;
        background-size: cover;
        bottom: -15px;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        transition: .5s;
      }

      &:hover,
      &:focus {

        &:after {
          background: url('/themes/medusa/assets/dist/images/icn-news-arrow-over.png');
          background-size: cover;
        }
      }
    }
  }
}