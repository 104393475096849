.flex-container {
  margin: 0 auto 10px auto;
  width: 100%;
  max-width: 100%;
  @include large-up {
    margin-bottom: 20px;

    &.alignleft {
      width: 40%;
      float: left;
      margin: 0 15px 10px 0;
    }

    &.alignright {
      width: 40%;
      float: right;
      margin: 0 0 10px 15px;
    }

    &.aligncenter {
      width: 40%;
      margin: 0 auto 10px auto;
    }
  }
}

.flex-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe,
  object,
  embed,
  video {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}