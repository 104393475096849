.two-column,
.three-column,
.four-column,
.five-column,
.six-column {
  @include medium-up {
    margin: 0 -15px;
  }
}

.two-column .col.two-thirds {
  @include medium-up {
    width: 67%;
  }
}

.two-column .col.one-third {
  @include medium-up {
    width: 33%;
  }
}