header {
  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  z-index: 200;
  display: inline-flex;
  padding: 0px 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transition: .5s;
  // flex-direction: column-reverse;

  &:focus {
    outline: none;
  }

  &.sticky {
    position: fixed;
    background: black;
    height: 80px;
    top: 0;

    .nav-wrapper {
      @media screen and (min-width: 1025px) {
        padding: 20px 0px;
        top: -55px;
      }
    }
  }
  @include medium-up {
    padding: 0px 50px;
  }
}