body.fixed {
  margin-top: 150px;
  overflow: hidden;

  @media (min-width: 1025px) {
    margin-top: 0;
    overflow: initial;
    position: relative;
  }
}

.nav-wrapper {
  position: absolute;
  background: transparent;
  display: inline-flex;
  right: 0;
  top: 25px;

  @media (min-width: 1025px) {
    position: relative;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    max-width: 1920px;
  }

  .nav-primary {
    position: relative;
    background: transparent;
    display: inline-flex;
    width: 100%;
    align-items: center;

    @media (min-width: 1025px) {
      display: inline-flex;
      height: 150px;
      z-index: 2;
      // width: auto;
    }

    .search-toggle {
      position: relative;
      margin: 0 15px;
      font-size: 30px;
      color: $primary-color;
      cursor: pointer;
      float: right;
      top: 0;
      background: url('/themes/medusa/assets/dist/images/ICN-Search-Button.png') no-repeat;
      background-size: contain;
      width: 32px;
      height: 32px;

      @media (min-width: 1025px) {
        float: none;
        width: 42px;
        height: 42px;
        margin: 0 25px;
      }
    }

    .nav-toggle {
      display: inline-block;
      position: relative;
      color: $primary-color;
      font-size: 30px;
      cursor: pointer;
      background: url('/themes/medusa/assets/dist/images/ICN-Menu.png') no-repeat;
      background-size: contain;
      width: 40px;
      height: 20px;

      @media (min-width: 1025px) {
        display: none;
      }

      .fa-times {
        display: none;
      }

      .fa-bars {
        display: block;
      }

      .text {
        color: $mobile-menu-bar-text;
        text-transform: uppercase;
        font-size: 16px;
        position: relative;
        top: -1px;
        font-family: $primary-font;
        font-weight: 400;
        margin-left: 10px;
      }
    }

    .nav-items {
      display: none;

      @media (min-width: 1025px) {
        display: inline-flex;
        width: 100%;
        justify-content: center;
      }

      ul {
        display: inline-flex;
        justify-content: space-around;
        width: 100%;
        list-style: none;
        padding: 0;

        li {
          margin-right: 10px;
          text-align: center;

          &:last-of-type {
            margin-right: 0;
          }
        }

        a {
          color: white;
          font-size: 22px;
          font-weight: 700;
          letter-spacing: .5px;
          text-transform: uppercase;
          text-decoration: none;

          &:hover,
          &:focus {
            color: $primary-color;
          }
        }
      }
    }
  }
}

.nav-items-wrapper {
  visibility: hidden;
  position: fixed;
  overflow: auto;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: $navigation-background-color;
  transform: translateY(0%);
  transition: transform 0.5s;
  top: 0;

  &.active {
    visibility: visible;
    top: 100%;
  }

  .close {
    position: absolute;
    padding: 3px 5px;
    width: 25px;
    height: 32px;
    color: $primary-color;
    position: absolute;
    text-align: right;
    font-size: 24px;
    cursor: pointer;
    z-index: 2;
    top: 20px;
    right: 20px;

    &:before {
      content: '\f00d';
      position: relative;
      font-size: 20px;
      color: $primary-color;
      font-weight: 900;
      font-family: 'Font Awesome 5 Free';
    }

    @media (min-width: 1025px) {
      top: 50px;
      right: 50px;
    }
  }

  ul {
    left: 0;
    width: 100%;
    margin: 0;
    position: absolute;
    padding: 50px 20px;
    top: 50px;
    list-style: none;
    z-index: 1;

    @media (min-width: 1025px) {
      padding: 100px 120px;
    }

    li {
      position: relative;
      margin: 0 0 45px;

      &:last-child {
        margin-right: 0;

        a {
          margin-right: 0;
        }
      }

      &:before {
        content: none;
      }

      .ul2 {
        &.open {
          .li2 {
            span {
              display: none;
            }

            .ul3 {
              position: relative;
              padding: 10px 0 0 10px;
              top: 0;
              background: transparent;
              display: block;

              .li3 {
                margin: 0;

                a {
                  padding-bottom: 20px;
                  font-style: italic;
                  white-space: normal;
                  color: white;
                }

                span {
                  display: none;
                }
              }
            }
          }
        }
      }

      &:hover {
        cursor: pointer;

        > a {
          border-bottom: 3px solid $primary-color;
        }

        .ul2 {
          .ul3 {
            position: relative;
            padding: 10px 0 0 10px;
            top: 0;
            background: transparent;

            .li3 {
              margin: 0;

              a {
                padding-bottom: 20px;
                font-style: italic;
                white-space: normal;
              }
            }
          }
        }
      }

      span.toggler {
        position: absolute;
        top: -15px;
        color: $primary-color;
        right: -15px;
        display: inline-flex;
        text-align: center;
        align-items: center;
        height: 50px;
        width: 50px;

        &:before {
          content: '';
          background: url('/themes/medusa/assets/dist/images/ICN-Plus.png') no-repeat;
          position: absolute;
          background-size: contain;
          height: 25px;
          width: 25px;
        }

        &.open {
          &:before {
            content: '';
            background: url('/themes/medusa/assets/dist/images/ICN-Minus.png') no-repeat;
            top: 25px;
          }
        }
      }

      a {
        display: block;
        padding: 0 0 10px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        font-style: normal;
        font-size: 24px;
        font-family: $primary-font;
        color: $header-background-color;
        border-bottom: 3px solid rgba(255, 255, 255, 0.30);

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    //Dropdown Level 1
    &.ul2 {
      display: none;
      padding: 25px 0;
      background: transparent;
      white-space: nowrap;
      height: auto;
      position: relative;
      top: 0;
      padding: 0;
      transform: none;
      min-height: auto;

      li {
        margin: 30px 0;

        @media (min-width: 1025px) {
          display: block;
          clear: both;
          width: 100%;
          margin: 0;

          a {
            border-bottom: none;
            padding: 30px 0;
            text-transform: none;
            font-weight: 100;
            font-style: normal;
            font-size: 18px;
            font-family: $primary-font;
            color: white;
            margin: 0;

            &:hover {
              background: $desktop-subnav-flyout-item-hover-color;
            }
          }
        }

        a {
          font-size: 18px;
          border-bottom: none;
          text-transform: none;
          color: $mobile-subnav-color;
        }
      }
    }
  }
}