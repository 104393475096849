.highlighted-boxes {
  padding: 60px 50px;
  position: relative;
  width: 100%;
  background: $medium-background;

  .boxes {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    display: block;
    position: relative;
    @include medium-up {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .box {
      position: relative;
      padding: 40px 0;
      width: 100%;
      @include medium-up {
        margin: 0 15px;
        // width: 33%;
        max-width: 310px;
      }

      a {
        width: 100%;

        // &:after {
        //   content: '';
        //   background: url('/themes/medusa/assets/dist/images/ICN-Right-Arrow.png');
        //   position: relative;
        //   bottom: 0;
        //   width: 35px;
        //   height: 35px;
        //   background-size: cover;
        //   bottom: 0;
        //   left: 0;
        //   right: 0;
        //   margin: auto;
        //   display: block;
        //   transition: .5s;
        // }

        &:hover {
          text-decoration: none;

          // &:after {
          //   background: url('/themes/medusa/assets/dist/images/ICN-Right-Arrow-Over.png');
          //   background-size: cover;
          // }

          .image {
            &:after {
              border-color: $secondary-color;
            }
          }
        }

        .image {
          position: relative;
          border-radius: 100%;
          width: 225px;
          height: 225px;
          background-repeat: no-repeat !important;
          background-position: center !important;
          background-size: cover !important;
          margin: 0 auto 40px;

          &:after {
            content: '';
            border: 10px solid rgba(0, 0, 0, 0.1);
            position: absolute;
            width: 255px;
            height: 255px;
            border-radius: 100%;
            top: -15px;
            left: -15px;
            transition: .5s;
          }
        }

        .blurb {
          text-align: center;

          h2 {
            color: $secondary-color;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 15px;
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}