main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
  }

  h1 {
    color: $primary-color;
    font-size: 48px;
    font-weight: 300;
  }

  h2 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 30px;
  }

  h3 {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 0;
  }

  h4 {
    font-size: 24px;
    font-weight: 400;
  }

  h5 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
  }

  h6 {
    font-size: 18px;
    font-weight: 500;
  }

  p,
  ul,
  ol {
    margin: 0 0 20px;
    line-height: 30px;
    color: $paragraph-text;
    font-size: 18px;
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $primary-color;
      text-decoration: underline;
    }

    &:visited {
      color: inherit;
    }

    &.btn-primary {
      padding: 15px 36px;
      background: $secondary-color;
      border-radius: 50px;
      color: white;
      text-decoration: none;
      letter-spacing: 2px;
      font-weight: 700;
      transition: .5s;

      &:hover {
        background: white;
        color: $secondary-color;
      }

      &.hide {
        display: none !important;
      }
    }
  }

  ul:not([class]),
  ol:not([class]) {
    list-style: none;
    padding-left: 30px;
    line-height: 24px;
    font-size: 16px;

    li {
      &:before {
        position: relative;
        display: inline-block;
        font-style: normal;
        font-weight: 900;
      }
    }
  }

  ul:not([class]) {
    li {
      text-indent: -4px;
      margin-bottom: 10px;

      &:before {
        content: '\f054';
        color: $primary-color;
        font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Pro', FontAwesome;
        font-size: 16px;
        left: -10px;
      }

      ul {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 16px;
        font-style: italic;

        li {
          &:before {
            color: $primary-color;
          }
        }
      }
    }
  }

  ol:not([class]) {
    counter-reset: numbers;

    li {
      counter-increment: numbers;
      text-indent: -5px;
      margin-bottom: 10px;

      &:before {
        content: counter(numbers);
        left: -5px;
        color: $primary-color;
      }

      ol {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 16px;
        font-style: italic;

        li {
          text-indent: -3px;

          &:before {
            content: '\f111';
            font-family: 'Font Awesome 5 Free', FontAwesome;
            font-weight: 900;
            display: inline-block;
            position: relative;
            font-size: 3px;
            top: -3px;
            left: -8px;
            color: $primary-color;
          }
        }
      }
    }
  }

  blockquote {
    position: relative;
    font-weight: 100;
    font-size: 24px;
    margin-right: 0;
    margin-left: 0;
    padding: 0 32px;
    color: $primary-color;

    p {
      line-height: 45px;
      color: $primary-color;
      font-weight: 500;
      margin: 0;
    }

    &:before {
      content: '';
      width: 12px;
      height: 100%;
      background-color: $primary-color;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50px;
    }
    @include medium-up {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 45px;

      p {
        font-size: 30px;
      }
    }
  }

  figure {
    figcaption {
      background: transparent;
      color: $figure-caption-color;
      padding: 15px 0px;
      font-size: 16px;
      font-weight: 300;
      font-style: italic;
      margin-top: 5px;
    }
  }
}

.green {
  color: #01650d !important;
}

.red {
  color: #e80b00 !important;
}

a.btn-primary {
  display: inline-block;
  text-transform: uppercase;
  padding: 10px 36px;
  background: $secondary-color;
  border-radius: 50px;
  color: white;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 700;
  transition: .5s;
  font-size: 16px;

  &:hover {
    background: #001d41;
    color: white;
  }

  &.hide {
    display: none !important;
  }

  &.email {
    &:before {
      content: '\f0e0';
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', FontAwesome;
      padding-right: 10px;
    }
  }
}