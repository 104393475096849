$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';

// fonts
$primary-font: 'Saira Semi Condensed', sans-serif;
$secondary-font: 'Raleway', sans-serif;

// colors
$primary-color: #f7641f;
$secondary-color: #f7641f;
$tertiary-color: #333333;
$heading-border-color: rgba(255, 255, 255, 0.25);
$header-background-color: #ffffff;

//Homepage Banners
$banner-heading-text: #f7641f;

//Sections
$light-background: #ffffff;
$medium-background: linear-gradient(120deg, rgba(248, 242, 252, 1) 0%, rgba(243, 229, 252, 1) 100%);
$dark-background: #001d41;

//Typography
$heading-text: #f7641f;
$heading-text-dark: #f7641f;
$heading-text-light: #ffffff;
$paragraph-text: #333333;

//Links
$link-border-color: rgba(213, 213, 213, 0.5);
$link-border-hover-color: #d5d5d5;
$link-visited-color: #666666;

//Images
$figure-caption-border: #d2d2d2;
$figure-caption-color: #585858;

//Tabs
$tabs-active: #f7641f;
$tabs-inactive: #001d41;
$tabs-text: #f7641f;

//Tables
$table-border: #cccccc;
$table-head-text: #ffffff;
$table-head-background: #f7641f;
$table-head-border: #f7641f;

//Accordions
$accordion-border: #d9d9d9;
$accordion-border-hover: #333333;
$accordion-border-active: #f7641f;
$accordion-title-hover: #333333;
$accordion-span: #f7641f;
$accordion-span-border: #f7641f;
$accordion-span-border-hover: #333333;
$accordion-span-hover: #333333;
$accordion-heading-color: #f7641f;

//Navigation
$navigation-background-color: linear-gradient(180deg, rgba(0, 0, 0, .95) 0%, rgba(0, 29, 65, .95) 100%);
$mobile-menu-bar-text: #595959;
$mobile-nav-border-color: #e5e5e5;
$mobile-subnav-color: #ffffff;
$mobile-subnav-background-color: transparent;
$desktop-subnav-flyout-color: #000000;
$desktop-subnav-flyout-item-hover-color: rgba(255, 255, 255, 0.25);

//Search
$search-background-color: linear-gradient(180deg, rgba(0, 0, 0, .95) 0%, rgba(0, 29, 65, .95) 100%);
$search-close-button-color: #f7641f;
$search-input-border-color: rgba(255, 255, 255, 0.25);

//News
$news-date-color: #404040;
$news-view-all-color: #ffffff;
$news-border-color: #d5d5d5;
$news-item-border-bottom-color: #e6e6e6;
$news-heading-color: #000000;
$news-heading-hover-color: #f7641f;
$news-read-more-color: #f7641f;
$news-post-date-color: #737373;
$news-search-background-color: #e5e5e5;
$news-search-input-border: #d9d9d9;

//Events
$events-date-color: #ffffff;
$events-view-all-color: #f7641f;
$events-heading-background: #001d41;
$events-item-border-bottom-color: #e6e6e6;
$events-heading-color: #ffffff;
$events-detail-heading: #650000;
$events-border-color: #cccccc;
$events-categories-bg-color: #f7f7f7;
$events-month-color: #323232;
$events-listing-date-color: #737373;
$events-listing-heading-color: #323232;

//Modals
$modal-heading-background: #001d41;
$modal-heading-color: #ffffff;

//Forms
$form-field-border-color: #cccccc;
$form-field-text-color: #333333;
$form-checkbox-active-color: #f7641f;
$form-radio-button-color: #f7641f;
$form-radio-button-border-color: #cccccc;
$form-submit-button-color: #f7641f;
$form-submit-button-hover-color: #333333;
$form-submit-button-border-color: #333333;
$form-submit-button-text-color: #ffffff;
$form-submit-button-text-hover-color: #ffffff;

//Sidebar
$sidebar-background: #ffffff;
$sidebar-border: #e5e5e5;

//Footer
$footer-background-color: #e4d7f5;
$footer-text-color: #333333;
$footer-link-color: #f7641f;
$footer-social-link-color: #f7641f;
$footer-social-link-hover-color: #333333;
$footer-link-after: #c3ade4;
$footer-divider: #cdc1dc;
$footer-bottom-link-after: #d5d5d5;

//Subpage Banner
$subpage-banner-heading-color: white;