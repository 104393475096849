.three-column-layout {
  main {
    padding: 100px 165px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .column {
      padding: 0 20px;
      width: 100%;

      @include medium-up {
        width: 33%;
      }
    }
  }
}