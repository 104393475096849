section.welcome {
  position: relative;
  width: 100%;
  color: $secondary-color;

  .message {
    margin: auto;
    padding: 75px 20px;
    max-width: 1240px;
    width: 100%;

    @media (min-width: 991px) {
      // padding: 90px 25px 150px;
    }

    h2 {
      color: black;
      font-size: 40px;
      line-height: 30px;
      margin-bottom: 55px;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
    }

    ul {
      font-size: 22px;
      line-height: 36px;
      font-weight: 700;

      img {
        display: inline-block;
        vertical-align: middle;
      }
    }

    p {
      // margin: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
    }

    a {
      color: $primary-color;
    }
  }

  .two-column {
    justify-content: center;
    margin: 0;

    .col {
      width: 100%;

      @media (min-width: 991px) {
        width: 50%;
        padding: 0 95px 15px 15px;
      }

      &:nth-of-type(2n) {
        width: 100%;
        background: #e7eaef;
        padding: 65px 40px 80px;
        border-radius: 10px;

        @media (min-width: 991px) {
          max-width: 375px;
        }
      }
    }
  }
}