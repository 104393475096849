.search-box,
.search-box-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
  padding: 60px 50px;
  width: 100%;
  height: 100%;
  background: $search-background-color;
  opacity: 0.95;

  &.active {
    display: block;
    top: 100%;
    // transform: scale(1.0525);
  }

  .fa-times {
    position: absolute;
    top: 20px;
    right: 20px;
    color: $search-close-button-color;
    text-align: right;
    font-size: 24px;
    cursor: pointer;

    @include medium-up {
      top: 50px;
      right: 50px;
    }
  }

  .search-container {
    position: relative;
    top: 150px;
    display: inline-flex;
    width: 100%;

    input[type='text'] {
      width: 100%;
      height: 70px;
      border: 0;
      border-bottom: 1px solid $search-input-border-color;
      background: transparent;
      color: white;
      font-size: 30px;
      padding-right: 50px;

      @include medium-up {
        font-size: 48px;
        padding-right: 75px;
      }

      &::placeholder {
        color: white;
        font-size: 30px;
        font-family: $primary-font;

        @include medium-up {
          font-size: 48px;
        }
      }

      &:focus {
        outline: 0;
      }
    }

    .btn-search {
      position: absolute;
      top: 15px;
      right: 0;
      background: url(/themes/medusa/assets/dist/images/ICN-Search.png);
      background-size: contain;
      background-repeat: no-repeat;
      color: white;
      font-weight: 100;
      font-size: 30px;
      width: 30px;
      height: 30px;

      @include medium-up {
        top: 5px;
        font-size: 48px;
        width: 50px;
        height: 50px;
      }

      span {
        display: none;
      }
    }
  }
}