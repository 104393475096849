table,
table.tablesaw {

  @include medium-up {
    border: 1px solid $table-border !important;
    border-radius: 5px;
    border-collapse: inherit;
    border-spacing: 0;
    overflow: hidden;
  }

  thead {
    background-color: $table-head-background;

    tr {
      th {
        color: $table-head-text;
        text-transform: none;
        font-size: 18px;
        font-weight: 700;
        padding: 20px;
        border: 1px solid $table-head-border;
        text-align: left;

        @include medium-up {
          border-bottom: 2px solid $table-head-border;
        }
      }
    }
  }

  tbody {
    tr {

      &:last-child {
        border-bottom: 0;
      }

      td {
        padding: 20px 0;
        border-bottom: 1px solid $table-border;
        font-size: 16px;

        @include medium-up {
          padding: 20px;
        }

        &:last-child {
          border-bottom: 1px solid $table-border;
          border-right: 0;
        }

        @include medium-up {
          border-right: 1px solid $table-border;
        }
      }
    }

    @include medium-up {
      td {
        &:last-of-type {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}