.sidebar-layout {
  overflow-x: hidden;

  main {
    padding: 0 0 80px !important;
    display: flex;
    flex-wrap: wrap;
    max-width: 1600px;
    margin: auto;

    .main-content {
      width: 100%;
      padding: 65px 20px 30px;
      position: relative;
      overflow: hidden;

      @include medium-up {
        width: 70%;
        padding: 100px 50px;
      }
    }

    .sidebar-content {
      width: 100%;
      background: $sidebar-background;
      padding: 30px 20px;

      @include medium-up {
        padding: 100px 50px;
        width: 30%;
        position: relative;

        &:before {
          content: '';
          background: $sidebar-background;
          width: 100vw;
          height: 100%;
          position: absolute;
          display: inline-block;
          z-index: 0;
          top: 0;
        }
      }
    }
  }

  &.left {
    main {
      .main-content {
        order: 1;


        &:before {
          content: '';
          background: #e5e5e5;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          display: block;
        }

        @include medium-up {
          order: 2;

          &:before {
            content: '';
            background: $sidebar-border;
            margin: 116px 0;
            width: 2px;
            height: 65%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .sidebar-content {
        order: 2;

        &:before {
          right: 100%;
        }

        @include medium-up {
          order: 1;
        }
      }
    }
  }

  &.right {
    main {
      .main-content {
        order: 1;

        &:after {
          content: '';
          background: #e5e5e5;
          width: 100%;
          position: absolute;
          bottom: 0;
          right: 0;
          height: 2px;
          display: block;
        }

        @include medium-up {
          padding: 100px 50px;

          &:after {
            content: '';
            background: $sidebar-border;
            margin: 116px 0;
            width: 2px;
            height: 65%;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }

      .sidebar-content {
        order: 2;

        @include medium-up {
          padding: 100px 50px;
        }

        &:before {
          left: 100%;
        }
      }
    }
  }

  &.two {
    .main-content {
      order: 1;
      width: 100%;
      padding: 65px 20px 30px;

      &:before {
        content: '';
        background: #e5e5e5;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        display: block;
      }

      @include medium-up {
        order: 2;
        width: 50%;
        padding: 100px 50px;

        &:before,
        &:after {
          content: '';
          background: $sidebar-border;
          margin: 116px 0;
          width: 2px;
          height: 65%;
          position: absolute;
          top: 0;
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }
      }
    }

    .sidebar-content {
      width: 100%;
      padding: 30px 20px;

      @include medium-up {
        padding: 100px 80px 100px 55px;
        width: 25%;
      }

      &.left {
        order: 2;

        &:before {
          right: 100%;
        }

        @include medium-up {
          order: 1;
          padding: 100px 50px;
        }
      }

      &.right {
        order: 3;

        &:before {
          left: 100%;
        }

        @include medium-up {
          order: 3;
          padding: 100px 50px;
        }
      }
    }
  }
}