.tabs {
  .tab-con {
    margin-bottom: 0;

    .tab-list {
      font-size: 18px;

      li {
        margin: 0 15px 0 0;

        &:before {
          display: none;
        }

        a {
          background: transparent;
          padding: 15px 35px 15px 0;
          color: $tabs-text;
          text-align: left;
          margin-bottom: 15px;

          &:before {
            content: '';
            width: 100%;
            height: 5px;
            background-color: $tabs-inactive;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            border-radius: 50px;
          }

          &:hover,
          &:focus {
            background: transparent;
            color: $tabs-text;

            &:before {
              background-color: $primary-color;
            }
          }

          &[aria-selected] {
            color: $tabs-text;
            background: transparent;

            &:before {
              background-color: $primary-color;
            }

            &:hover,
            &:focus {
              background: transparent;
              color: $tabs-text;
            }

            &[role="tab"] {
              &:focus {
                background: transparent;
                color: $tabs-text;
              }
            }
          }
        }
      }
    }
  }

  .tab-content {
    border: 1px solid #dbdbdb;
    background: white;
    padding: 25px;
    border-radius: 5px;
    font-size: 18px;

    @include medium-up {
      padding: 45px;
    }
  }
}