.news-posts {
  position: relative;
  padding-top: 60px;

  .news-post {
    width: 100%;
    border-bottom: 2px solid $news-item-border-bottom-color;
    padding: 40px 0 80px;

    &:last-of-type {
      border: none;
    }

    .news-post-headline {
      margin-bottom: 10px;

      a {
        border: none;
        color: $news-heading-color;

        &:visited {
          color: $news-heading-color;
        }

        &:hover,
        &:focus {
          color: $news-heading-hover-color;
          text-decoration: none;
        }
      }
    }

    .news-post-date {
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: 600;
      color: $news-post-date-color;

    }

    .news-post-read-more {
      a {
        color: $news-read-more-color;
        font-weight: 700;
        transition: .5s;

        &:hover,
        &:focus {
          color: $tertiary-color;
          text-decoration: none;
        }
      }
    }
  }

  .pager {
    position: absolute;
    text-align: center;
    width: 100%;

    &.top {
      top: -50px;
    }

    &.bottom {
      bottom: -20px;
    }

    span.page,
    a {
      font-size: 15px;
      position: relative;
      display: inline-block;
      margin-right: 5px;
      border: 2px solid #eeeeee;
      border-radius: 25px;
      width: 30px;
      height: 30px;
      text-align: center;
      padding: 5px;

      .fas {
        position: relative;
        top: 1px;
      }

      &.aspNetDisabled,
      &.hidden {
        display: none;
      }

      &:hover,
      &:focus {
        background-color: $primary-color;
        color: white;
        text-decoration: none;
      }

      &.more {
        font-size: 14px;
        text-align: center;
      }
    }

    span.next-prev {
      background-color: $primary-color;
      color: white;
    }
  }

  span.page {
    background-color: $primary-color;
    color: white;
  }
}

.news-search {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $news-search-background-color;
  padding: 50px;

  &:before,
  &:after {
    content: '';
    background: $news-search-background-color;
    width: 100vw;
    height: 100%;
    position: absolute;
    display: inline-block;
    z-index: 0;
    top: 0;
  }

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }

  a {
    padding: 15px 35px;
    margin-left: 0;
    background: $primary-color;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 25px;
    color: white;
    letter-spacing: 2px;
    font-weight: 700;
    transition: .5s;
    border: 1px solid $primary-color;

    &:hover,
    &:focus {
      background: white;
      color: $primary-color;
      text-decoration: none;
    }
  }

  input[type='text'] {
    display: block;
    padding: 10px;
    width: 100%;
    margin-right: 10px;
    border: 2px solid $news-search-input-border;
    background: white;
    color: $form-field-text-color;
    font-weight: 300;
    font-size: 16px;
    border-radius: 25px;

    &:focus {
      outline: none;
      border-color: $primary-color;
    }
  }
}

.news-detail {
  .news-detail-headline {
    margin-bottom: 10px;
  }

  .news-detail-date {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 600;
    color: $news-post-date-color;
  }

  .news-detail-copy {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 30px 0;
  }

  .news-see-more {
    margin-top: 30px;

    a {
      color: $primary-color;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
      transition: .5s;
      font-size: 16px;

      &:before {
        content: '\f053';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        position: relative;
        display: inline-block;
        padding-right: 10px;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: $tertiary-color;
      }
    }
  }
}