.modal-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.modal {
  border-radius: 10px;

  .modal-content {
    padding: 0;
    text-align: left;
    border-radius: 10px;

    h1 {
      margin: 0;
      position: relative;
      color: $modal-heading-color;
      z-index: 0;
      padding: 45px 40px;

      &:before {
        content: '';
        background: $modal-heading-background;
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%;
        left: 0;
        z-index: -1;
      }
    }

    p {
      padding: 45px 40px;
    }
  }
}