// footer
footer {
  background-color: #262627;
  color: $footer-text-color;
  @include medium-up {
  }

  .three-column {
    justify-content: center;

    .col {
      max-width: 435px;
      padding: 78px 47px;
      margin: 0 auto;
      @include medium-up {
        margin: 0;
      }

      &:nth-of-type(even) {
        background: #f3f4f7;
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary-color;
    font-size: 24px;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 30px;
  }

  p {
    font-size: 22px;
    line-height: 30px;
  }

  .footer-top {
    display: block;
    background: white;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 0;
    padding: 0 20px 0;
    @include medium-up {
      padding: 0 50px 0;
      // text-align: left;
    }

    .footer-copy {
      position: relative;
      width: 100%;

      img {
        margin: auto;
      }
    }

    .footer-links {
      position: relative;
      width: 100%;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 25px 0 0;

          a {
            color: $footer-link-color;
            text-transform: uppercase;
            position: relative;
            letter-spacing: 2px;
            text-decoration: none;
            font-weight: 700;
            font-size: 14px;

            &:hover,
            &:focus {
              color: $tertiary-color;
            }
          }

          &:first-of-type {
            a {
              padding-left: 0;
            }
          }

          &:last-of-type {
            a {
              padding-right: 0;

              &:after {
                display: none;
              }
            }
          }
        }
      }
      @include medium-up {
        text-align: right;

        ul {
          display: inline-flex;

          li {
            padding: 0;

            a {
              padding: 0 12px;

              &:after {
                content: '|';
                position: absolute;
                right: -3px;
                color: $footer-link-after;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    color: white;
    padding: 60px 20px;
    border-bottom: 2px solid #3c3c3d;
    margin: 0 20px;
    @include medium-up {
      padding: 70px 50px;
      margin: 0 50px;
    }

    .footer-information {
      font-size: 14px;
      text-align: center;

      .three-column {
        flex-wrap: nowrap;
      }

      .col {
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 0;
        }
        @include medium-up {
          margin: 0;
        }
      }

      img {
        margin: 0 auto 30px;
      }

      p {
        margin: 10px 0;

        strong {
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: 700;
        }

        a {
          color: white;
          text-decoration: none;
          position: relative;
          width: auto;
          display: inline-block;

          &:after {
            position: relative;
            right: 0;
            left: 0;
            display: block;
            margin: auto;
            width: 100%;
            height: 1px;
            height: 0.0625rem;
            background-color: $footer-bottom-link-after;
            content: '';
            opacity: 0.5;
          }

          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .footer-socials {
    display: flex;
    padding: 50px 0;
    justify-content: space-around;

    .social-nav {
      position: relative;
      @include medium-up {
        text-align: right;
      }

      ul {
        list-style: none;
        display: inline-flex;
        padding: 0;

        li {
          margin-right: 40px;

          a {
            color: white;
            font-size: 24px;
            transition: .5s;

            &:hover,
            &:focus {
              color: $primary-color;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.footer-antilles {
  display: flex;
  width: 100%;
  align-items: center;
  background: black;
  position: absolute;
  left: 0;
  text-align: center;
  align-content: center;
  justify-content: center;
  padding: 40px 50px;

  .brand {
    display: inline-block;
    position: relative;
    margin-right: 25px;

    &:after {
      position: absolute;
      right: -25px;
      width: 1px;
      height: 100%;
      background-color: #5b6168;
      content: '';
      top: 0;
    }
  }

  .tagline {
    margin-left: 25px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    position: relative;
    display: inline-block;

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}