.subpage {
  position: relative;
  overflow-x: hidden;

  main {
    padding: 65px 20px 80px;
    position: relative;

    h1 {
      margin-top: 0;
    }

    @include medium-up {
      padding: 100px 50px;
      max-width: 1600px;
      justify-content: center;
      margin: 0 auto;
    }
  }
}