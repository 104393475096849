header {
  &.sticky {
    .branding {
      padding: 20px 0;

      a {
        position: absolute;
        top: 0;
        @include medium-up {
          position: relative;
          top: 50%;
        }
      }
    }
  }
}

.branding {
  position: relative;
  display: inline-block;
  background: transparent;
  top: 0;
  padding: 20px 0;
  @include medium-up {
    padding: 50px 0px;
  }

  @media (min-width: 1920px) {
    padding: 50px 0px 100px;
  }

  a {
    display: inline-block;
    position: relative;
    height: 85px;
    width: 310px;
    margin: auto;
    z-index: 1;

    @media screen and (min-width: 600px) {
      height: 60px;
      width: 560px;
    }

    @media screen and (min-width: 1024px) {
      width: 850px;
      height: 200px;
    }

    .logo {
      display: inline-block;
      background-position: center;
      background: url(/themes/medusa/assets/dist/images/logo-distress-bandana.png) no-repeat;
      background-size: cover;
      height: 60px;
      width: 220px;
      margin: auto;

      @media screen and (min-width: 600px) {
        height: 115px;
        width: 420px;
      }
      @media screen and (min-width: 1024px) {
        height: 180px;
        width: 610px;
      }
    }

    .bandanna {
      display: inline-block;
      background-position: center;
      background: url(/themes/medusa/assets/dist/images/logo-twinkling-and-shimmering.gif) no-repeat;
      background-size: cover;
      height: 64px;
      width: 60px;

      @media screen and (min-width: 600px) {
        height: 123px;
        width: 115px;
      }

      @media screen and (min-width: 1024px) {
        height: 213px;
        width: 200px;
      }
    }
  }
}