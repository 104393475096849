.featured-events {
  background: $medium-background;
  padding: 60px 20px;

  @include medium-up {
    padding: 110px 20px;
  }

  h2 {
    margin: 0 0 20px;
    color: $heading-text;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    font-style: normal;
    font-size: 30px;
    text-align: center;

    &:before {
      content: '';
      background: url('/themes/medusa/assets/dist/images/ICN-Events.png') no-repeat;
      width: 95px;
      height: 95px;
      position: relative;
      display: block;
      background-size: contain;
      margin: 0 auto 30px;
    }

    &:after {
      position: relative;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      margin: 20px auto;
      width: 75px;
      height: 5px;
      background: url('/themes/medusa/assets/dist/images/DIVIDER.png') no-repeat;
      content: '';
      background-size: contain;
    }
  }

  .events {
    padding: 30px 0;

    @include medium-up {
      display: flex;
      padding: 50px 0;
      max-width: 1600px;
      margin: 0 auto;
    }

    .event {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      vertical-align: top;
      background: white;
      border-radius: 10px;
      margin: 15px 0;
      box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.25);

      @include medium-up {
        width: 33%;
        margin: 0 15px;
      }

      h3 {
        margin-bottom: 0;
        font-size: 24px;

        a {
          color: $events-heading-color;
          text-decoration: none;
          line-height: 24px;
          border: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }

      .event-image {
        width: 100%;
        display: none;
      }

      .event-title {
        margin: 0;
        position: relative;
        color: $events-heading-color;
        z-index: 0;
        padding: 45px 40px 0;

        @include medium-up {
          padding: 45px 40px 0;
        }

        &:before {
          content: '';
          background: $events-heading-background;
          position: absolute;
          width: 100%;
          top: 0;
          height: 100%;
          left: 0;
          z-index: -1;
        }
      }

      .event-blurb,
      .event-date {
        font-size: 16px;
        line-height: 24px;
      }

      .event-blurb {
        padding: 45px 40px 0px;
      }

      .event-date {
        color: $events-date-color;
        letter-spacing: 1px;
        font-weight: 500;
        margin: 0;
        position: relative;
        z-index: 0;
        display: block;
        padding: 0 40px 45px;

        &:before {
          content: '';
          background: $events-heading-background;
          position: absolute;
          width: 100%;
          top: 0;
          height: 100%;
          left: 0;
          z-index: -1;
        }
      }

      .event-read-more {
        width: 100%;
        left: 0;
        display: inline-block;
        bottom: 0;
        padding: 0 40px 45px;

        a {
          color: $events-view-all-color;
          text-decoration: none;
          font-weight: 700;
          font-size: 14px;

          &:hover,
          &:focus {
            color: $tertiary-color;
          }
        }
      }
    }
  }

  .view-all-events {
    color: $events-view-all-color;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    border: none;
    text-align: center;
    padding: 35px 0 0;

    a {
      color: $events-view-all-color;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      border: none;
      letter-spacing: 1px;

      &:after {
        content: '';
        background: url('/themes/medusa/assets/dist/images/ICN-Right-Arrow.png');
        position: relative;
        bottom: 0;
        width: 35px;
        height: 35px;
        background-size: cover;
        bottom: -15px;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        transition: .5s;
      }

      &:hover,
      &:focus {

        &:after {
          background: url('/themes/medusa/assets/dist/images/ICN-Right-Arrow-Over.png');
          background-size: cover;
        }
      }
    }
  }
}