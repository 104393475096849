a.scroll-to-top {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 100;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $primary-color;
  text-align: center;
  opacity: 0;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0s;

  &:hover {
    background-color: $tertiary-color;
    text-decoration: none;

    &:before {
      color: $primary-color;
    }
  }

  &.move {
    bottom: 150px;

    @media only screen and (max-width: 840px) {
      bottom: 85px;
    }
  }

  &:before {
    position: absolute;
    width: 20px;
    height: 25px;
    content: '';
    content: '';
    background: url('/themes/medusa/assets/dist/images/ICN-Back-To-Top-Arrow.png') no-repeat;
    background-size: cover;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }

  @media only screen and (max-width: 768px) {
    right: 25px;
    bottom: 50px;
  }
}