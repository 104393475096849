section.banner {
  position: relative;
  height: 100vh;
  max-height: 1080px;

  .slide {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:before {
      content: '';
      height: 100%;
      // background: rgba(0, 0, 0, 0.55);
      position: absolute;
      width: 100%;
    }

    .caption {
      position: absolute;
      // width: 100%;
      color: white;
      padding: 0 20px;
      text-align: center;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      .title {
        margin-top: 0;
        margin-bottom: 10px;
        color: $banner-heading-text;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 30px;
        text-shadow: 2px 4px 2px rgba(0, 0, 0, 0.75);
        @media screen and (min-width: 1024px) {
          font-size: 60px;
        }
      }

      .blurb {
        margin: auto;
        padding-bottom: 15px;
        max-width: 780px;
        width: 100%;
        font-size: 16px;
        line-height: 30px;
        @media screen and (min-width: 1024px) {
          font-size: 20px;
        }
      }

      .button {
        padding: 25px 0 0;
        text-transform: uppercase;
        font-size: 16px;
        display: block;

        &.hide {
          display: none;
        }

        a {
          padding: 15px 36px;
          background: $secondary-color;
          border-radius: 50px;
          color: white;
          text-decoration: none;
          letter-spacing: 2px;
          font-weight: 700;
          transition: .5s;

          &:hover {
            background: white;
            color: $secondary-color;
          }
        }
      }
    }
  }

  .jump-alert {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 5%;
    z-index: 1;
    display: block;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    color: #ffffff;
    background: url('/themes/medusa/assets/dist/images/ICN-Arrow.png') no-repeat;
    background-size: cover;
  }
}