// content area
body {
  .container {
    transition: transform 0.5s;
  }

  &.open {
    overflow-x: hidden;

    .container {
      transform: scale(0.95);
    }
  }
}