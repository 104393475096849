table,
table.tablesaw {
  width: 100%;
  max-width: 100%;
  margin: 0 0 24px 0;
  padding: 0;
  empty-cells: show;
  border: 0;
  border-collapse: collapse;
  @include fontsize(14 17);
}

.tablesaw * {
  box-sizing: border-box;
}

.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
  display: none;
}

@media only all {
  .tablesaw-stack {
    clear: both;

    td,
    th {
      display: block;
      text-align: left;
    }

    tr {
      display: table-row;
      clear: both;
    }

    td .tablesaw-cell-label,
    th .tablesaw-cell-label {
      display: inline-block;
      width: 30%;
      padding: 0 10px 0 0;
    }

    th .tablesaw-cell-label-top,
    td .tablesaw-cell-label-top {
      display: block;
      margin: 6px 0;
      padding: 6px 0;
    }

    .tablesaw-cell-label {
      display: block;
    }

    tbody th.group {
      margin-top: -1px;
    }

    th.group b.tablesaw-cell-label {
      display: none !important;
    }
  }
}

@media (max-width: 39.9375em) {
  table.tablesaw-stack {
    margin: 0 -20px 24px -20px;
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);

    tbody tr {
      display: block;
      width: 100%;
      padding: 0 20px;

      &:last-child {
        border-bottom: 1px solid gray;
      }
    }

    thead {
      display: none;
    }

    tbody td,
    tbody th {
      display: block;
      clear: left;
      width: 100%;
      padding: 0;

      &:last-child {
        border-bottom: 0;
      }
    }

    td .tablesaw-cell-label {
      display: inline-block;
      padding: 8px 8px 8px 0;
      text-transform: uppercase;
      vertical-align: top;
      background: transparent;
      font-weight: 700;
    }

    td .tablesaw-cell-content {
      display: inline-block;
      max-width: 67%;
      padding: 8px 0 8px 8px;
    }

    .tablesaw-stack-block .tablesaw-cell-label,
    .tablesaw-stack-block .tablesaw-cell-content {
      display: block;
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    td:empty,
    th:empty {
      display: none;
    }
  }
}

@media (min-width: 40em) {
  .tablesaw-stack {
    tr {
      display: table-row;
    }

    td,
    th,
    thead td,
    thead th {
      display: table-cell;
      margin: 0;
    }

    td .tablesaw-cell-label,
    th .tablesaw-cell-label {
      display: none !important;
    }

    thead {
      th {
        padding: 10px 18px 8px;
        color: white;
        text-transform: uppercase;
        font-weight: 700;

        &:last-child {
          border-right: 0;
        }
      }
    }

    tbody {
      // border-bottom: 1px solid gray;
      tr {
        &:nth-child(odd) {
          // background: $concrete;
        }

        &:nth-child(even) {
          // background: $mercury;
        }

        td {
          padding: 12px 18px 10px;

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}