.events-list-view {
  position: relative;

  .events-category-name {
    display: none;
  }

  .event-month {
    text-transform: uppercase;
    font-weight: 700;
    padding-top: 60px;
    padding-bottom: 10px;
    font-size: 24px;
    display: block;
    letter-spacing: 1px;
    color: $events-month-color;
    border-bottom: 2px solid $events-border-color;

    &:first-of-type {
      padding-top: 0;
    }

    &:empty {
      display: none;
    }
  }

  .event {
    width: 100%;
    padding-bottom: 60px;

    &:last-of-type {
      border-bottom: none;
    }

    .event-title {
      text-transform: uppercase;
      font-size: 30px;
      margin-bottom: 10px;
      font-weight: 700;

      a {
        border: none;
        color: $events-listing-heading-color;

        &:visited {
          color: $events-listing-heading-color;
        }
      }
    }

    .event-date {
      font-size: 18px;
      letter-spacing: 1px;
      display: block;
      margin-bottom: 20px;
      font-weight: 600;
      color: $events-listing-date-color;

    }

    .event-read-more {
      border-bottom: none;
      font-weight: 700;
      font-size: 18px;

      &:hover,
      &:focus {
        border-bottom: 1px solid $link-border-color;
        text-decoration: none;
      }

      &:visited {
        color: $primary-color;
      }
    }
  }
}

.event-content {

  .event-details-title {
    color: $events-detail-heading;
    margin-top: 0;
    line-height: 24px;
  }

  .event-details-date span {
    display: inline-block;
    font-size: 18px;
    letter-spacing: 1px;
    margin-bottom: 5px;
    font-weight: 600;
    text-transform: uppercase;

    span {
      color: #999999;
    }
  }

  .event-details-location {
    border-bottom: 2px solid $events-item-border-bottom-color;
    padding-bottom: 25px;
    margin-bottom: 35px;
  }

  .returnlink {
    border-top: 2px solid $events-item-border-bottom-color;
    margin-top: 40px;
    padding-top: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;

    span {
      margin-right: 5px;
    }
  }

  a {
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
      border-bottom: 1px solid $link-border-color;
      text-decoration: none;
    }

    &:visited {
      color: $primary-color;
    }
  }
}

.event-details {
  .event-details-title {
    font-size: 36px;
  }

  .event-details-image {
    display: none;
  }

  .event-details-date,
  .event-details-location {
    font-size: 24px;
    line-height: 36px;
    color: #737373;
    font-weight: 600;
  }

  .event-details-copy {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 60px 0 80px;
    margin: 40px 0 30px;
  }

  .returnlink,
  .ical {
    font-size: 16px;
    color: $primary-color;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 15px;

    a {
      cursor: pointer;
      transition: .5s;

      &:before {
        font-family: 'Font Awesome 5 Free';
        position: relative;
        display: inline-block;
        font-size: 16px;
        width: 25px;
        padding-right: 10px;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: $tertiary-color;
      }
    }
  }

  .returnlink {
    a {
      &:before {
        content: '\f053';
      }
    }
  }

  .ical {
    a {
      &:before {
        content: '\f073';
      }
    }
  }
}