.accordions {
  .accordion-title {

    button {
      border-bottom: 2px solid $accordion-border !important;
      font-size: 18px !important;
      font-weight: 300;
      font-family: $primary-font !important;
      color: $accordion-heading-color !important;
      line-height: 24px !important;

      &:after {
        content: '\f067';
        font-family: 'Font Awesome 5 Free';
        position: absolute;
        right: 0;
        font-weight: 900;
        color: $accordion-span !important;
        height: 36px;
        border-bottom: 2px solid $accordion-span-border;
        padding: 0 15px;
        margin-left: 15px;
      }

      &:before {
        content: '';
        background: white;
        width: 15px;
        height: 2px;
        position: absolute;
        right: 45px;
        bottom: -2px;
        z-index: 1;
      }

      &:hover,
      &:focus {
        border-bottom: 2px solid $accordion-border-hover !important;
        color: $accordion-title-hover !important;

        &:after {
          color: $accordion-span-hover !important;
          border-bottom: 2px solid $accordion-span-border-hover;
        }
      }
    }

    &.is-active {
      button {
        color: $accordion-heading-color !important;
        border-bottom: 2px solid $accordion-border-active !important;

        &:after {
          content: '\f068';
          font-family: 'Font Awesome 5 Free';
          position: absolute;
          right: 0;
          font-weight: 900;
          color: $accordion-span !important;
        }

        &:focus {
          color: $accordion-span !important;
          border-bottom: 2px solid $accordion-border-active !important;

          &:after {
            border-bottom: 2px solid $accordion-span-border !important;
          }
        }
      }
    }
  }
}